let url = "";

if (process.env.NODE_ENV === 'development') {
    url = "http://localhost:8000";
} else {
    url = "https://api.autochargebr.com.br";
}

export const Constants = {
    url: url,
    baseUrl: url + "/api/parceiro",
};
