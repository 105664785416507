export default {
	header: {
		self: {},
		items: [
			{
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "Eletropostos",
				root: true,
				icon: "fas fa-charging-station",
				page: "eletropostos",
				bullet: "dot"
			},
			{
				title: "Relatórios",
				root: true,
				bullet: "dot",
				icon: "fas fa-chart-pie",
				submenu: [
					{
						title: "Relatório de recargas",
						bullet: "dot",
						page: "relatorio/relatorio01"
					},
					{
						title: "Erros reportados",
						bullet: "dot",
						page: "relatorio/relatorio02"
					},
				]
			},
		]
	}
};
