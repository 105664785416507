import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const DashboardPage = lazy(() =>
	import("./dashboard/DashboardPage")
);

const EletropostoPage = lazy(() =>
	import("./eletroposto/EletropostoPage")
);

const RelatorioPage = lazy(() =>
	import("./relatorio/RelatorioPage")
);

export default function HomePage() {

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					<Redirect exact from="/" to="/dashboard" />
				}

				<Route path="/dashboard" component={DashboardPage} />
				<Route path="/eletropostos" component={EletropostoPage} />
				<Route path="/relatorio" component={RelatorioPage} />

				<Redirect to="/error/error-v6" />
			</Switch>
		</Suspense>
	);
}
